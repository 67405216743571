@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");

.firstComponent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212529;
  text-align: center;
}
.parent-login{
  width: 470px;
  height: 650px;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  top: -60px;
}
.child-first-login h2 {
  margin: 0%;
}

.child-first-login {

  width: 100%;
  height: 200px;
  /* background-color: brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.heading-section-login {
  font-size: 40px;
  color: #ffffff;
  line-height: 1.5;
  font-weight: 400;
  font-family: "Lato", Arial, sans-serif;
}

.child-second-login {
  width: 100%;
  height: 600px;
  background: #000232;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.nested-child-login-first {
  margin-top: 20px;
  width: 90%;
  height: 120px;
 display: flex;
 flex-direction: column;
 padding-top: 20px;
}
.nested-child-login-first h3 {
  font-weight: 400;
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.nested-child-login-first p {
  font-family: "Lato", Arial, sans-serif;
  font-size: 19px;
  line-height: 1.8;
  font-weight: normal;
  margin: 0%;
  color: rgba(255, 255, 255, 0.5);
}

.nested-child-login-second {
  height: 250px;
  display: flex;
  flex-direction: column;
  /* background-color: #2d1b1b; */
  gap: 20px;
  padding-top: 15px;
}
.user-info {
  width: 300px;
  height: 60px;
  display: flex;
  /* background-color: blueviolet; */
  justify-content: space-between;
  align-items: center;
}

.fa-user {
  color: #fff !important;
  position: absolute;
  bottom: 20px;
  z-index: 100;
  top: 15px;
 
}

.user-info .icon span {
  display: inline-block;
  font-size: 20px;
  color: #fff;
}

#ic {
  color: #fff;
  width: 20px;
}
 input {
  outline: none !important;
}

.login-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-wrap {
  position: relative;
  background: #000232;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
}

.login-wrap h3 {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.login-wrap p {
  color: rgba(255, 255, 255, 0.5);
}

.text-center {
  text-align: center !important;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

form {
  display: block;
  margin-top: 0em;
}

.form-group {
  width: 350px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.form-group .icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 48px;
  background: transparent;
  font-size: 18px;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.form-group .justify-content-center {
  width: 500px;
  height: 500px;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.form-group .icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 48px;
  background: transparent;
  font-size: 18px;
}

.form-control {
  width: 350px;
  height: 48px;
  background: rgba(0, 0, 0, 0.05);
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #b689b0;
  padding-left: 30px;
  padding-right: 0;
  letter-spacing: 1px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
span {
  color: #fff;
}
input {
  font-size: 20px;
}

.form-group-one {
}

.btn.btn-primary {
  background: #b689b0 !important;
  border: 1px solid #b689b0 !important;
  color: #fff !important;
}
.btn {
  cursor: pointer;

  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 15px;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

.submit-btn {
  width: 350px !important;
  height: 60px !important;
  background-color: #fff;
  border-radius: 5px;
}

.btn {
  width: 100%;
  height: 100%;
  font-size: 18px;
  background: #b689b0 !important;
  border: 1px solid #b689b0 !important;
  color: #fff !important;
  border-radius: 5px;
  letter-spacing: 1px;
}
.btn:hover {
  outline: none;
}
